// export const baseUrl = 'http://192.168.0.123:8092/'
export const baseUrl='http://120.68.194.114:8092/'
import Cookies from 'js-cookie'


const TokenKey = 'Admin-Token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

