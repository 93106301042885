import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/global.css"
import request from "@/utils/request";
import store from "./store"
import axios from "axios";
import './assets/icons' // icon
// 设置Axios的默认配置
axios.defaults.baseURL = 'http://192.168.0.123:8092/';
// axios.defaults.baseURL = 'http://120.68.194.114:8092/';
Vue.prototype.axios = axios;

import { getDicts } from "@/api/dict/data";

//请求允许携带cookie
import $http from 'axios'
$http.defaults.withCredentials=true;

//打印
// @ts-ignore
import Print from 'vue-print-nb';
Vue.use(Print);

// 字典标签组件
// @ts-ignore
import DictTag from '@/components/DictTag'

// 字典数据组件
import DictData from '@/components/DictData'
// 图片上传组件
// @ts-ignore
import ImageUpload from "@/components/ImageUpload"
// 分页组件
// @ts-ignore
import Pagination from '@/components/Pagination'
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/global";
// 全局方法挂载
Vue.prototype.getDicts = getDicts


// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)
Vue.component('ImageUpload', ImageUpload)
DictData.install()


Vue.use(ElementUI, {size:"mini"});
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.config.productionTip = false
Vue.prototype.request=request
Vue.prototype.handleTree = handleTree
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// src/main.js

Vue.prototype.$EventBus = new Vue()

