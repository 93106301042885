import axios from 'axios'
import Element, {Message, MessageBox} from 'element-ui'
import ElementUI from "element-ui";
import router from "@/router";
import {baseUrl, getToken} from "../../public/config";
import {tansParams} from "@/utils/global";
import errorCode from "@/utils/errorCode";
// 是否显示重新登录
export let isRelogin = {show: false};
const request = axios.create({
    baseURL: baseUrl,  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    timeout: 50000
})
/**/
// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    //取出sessionStorge里面缓存的用户信息
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        // config.headers['Authorization'] = 'Bearer' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers['Authorization'] = getToken()
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?' + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    // let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    // if (user) {
    //     config.headers['token'] = user.token;
    // }


    return config
}, error => {
    return Promise.reject(error)
});
// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    res => {
        console.log(res.data.status)
        // 未设置状态码则默认成功状态
        const code = res.data.code || "200";
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode['default']
        // 二进制数据则直接返回
        if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
            return res.data
        }
        if (code === "401") {
            if (!isRelogin.show) {
                isRelogin.show = true;
                MessageBox.confirm(res.data.msg, '系统提示', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    isRelogin.show = false;
                    // store.dispatch('LogOut').then(() => {
                    //     location.href = '/index';
                    // })
                    router.push("/login")
                }).catch(() => {
                    isRelogin.show = false;
                });
            }
            return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
        } else if (code === "500") {
            Message({message: msg, type: 'error'})
            return Promise.reject(new Error(msg))
        } else if (res.data.status=="500") {
            Message({message: res.data.message, type: 'error'})
            return Promise.reject(new Error(res.data.message))
        } else if (code === "601") {
            Message({message: msg, type: 'warning'})
            return Promise.reject('error')
        }
            // } else if (code !== "200") {
            //     ElementUI.Notification.error({title: msg})
            //     return Promise.reject('error')
        // }
        else {

            return res.data
        }

    },
    error => {
        console.log('err' + error)
        let {message} = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({message: message, type: 'error', duration: 5 * 1000})
        return Promise.reject(error)
    }
)
export default request

