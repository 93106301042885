<template>
  <div class="component-upload-image">
    <el-upload
        multiple
        :action="uploadImgUrl"
        :headers="headers"
        list-type="picture-card"
        :before-upload="handleBeforeUpload"
        :limit="limit"
        :on-error="handleUploadError"
        ref="imageUpload"
        name="file"
        :on-success="handleUploadSuccess"
        :auto-upload="true"
        :show-file-list="false"
        :file-list="fileList"
        :class="{hide: showfileList}"
    >
      <i class="el-icon-plus">{{ imageName }}</i>
    </el-upload>
    <el-image :src="dialogImageUrl" class="showImage" v-show="showImg===1" @click="handlePictureCardPreview"/>
    <el-button type="primary" icon="el-icon-camera-solid" @click="handleDelete" v-show="showBtn===1" style="cursor: pointer">删除</el-button>
  </div>
</template>

<script>

import {baseUrl, getToken} from "../../../public/config";

export default {
  props: {
    value: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 5,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 10,
    },
    imageName: "",
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },

  },
  data() {
    return {
      headers: {
        Authorization: getToken(), //headers属性中添加token，这个属性是el-upload自带的用来设置上传请求头部
      },
      showfileList: false,
      number: 0,
      showBtn: 0,
      showImg: 0,
      uploadList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      uploadImgUrl: baseUrl + "/picture/upload", // 上传的图片服务器地址
      fileList: [],
      fileIamge: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          // 首先将值转为数组
          const list = Array.isArray(val) ? val : this.value.split(',');
          // 然后将数组转为对象数组
          this.fileList = list.map(item => {
            if (typeof item === "string") {
              if (item.indexOf(this.baseUrl) === -1) {
                item = {name: this.baseUrl + item, url: this.baseUrl + item};
              } else {
                item = {name: item, url: item};
              }
            }
            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    // 上传前loading加载
    handleBeforeUpload(file) {
      let isImg = false;
      if (this.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = this.fileType.some(type => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }

      if (!isImg) {
        this.$message.error(
            `文件格式不正确, 请上传${this.fileType.join("/")}图片格式文件!`
        );
        return false;
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传头像图片大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }

      this.loading = this.$loading({
        lock: true,
        text: "上传中",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },

    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 上传成功回调
    handleUploadSuccess(res, file) {
      this.fileIamge = file
      this.fileList.push({name: res.fileName, url: res.url});
      const picUrl = this.listToString(this.fileList);
      this.dialogImageUrl = picUrl
      this.picurl = picUrl;
      this.showImg = 1;
      this.showfileList = true;
      this.showBtn = 1;
      this.$emit("imageData", res.result);
      this.loading.close();
      console.log("图片res:"+res.result+"@@图片file："+file)
    },
    // 删除图片
    handleDelete() {
      this.fileIamge = this.fileIamge
      this.dialogImageUrl = this.dialogImageUrl;
      const path = this.dialogImageUrl;
      this.showImg = 0;
      this.showfileList = false;
      this.fileList.splice(-1, 1);
      this.showBtn = 0;

      this.axios.get("/picture/delete", {
        params: {
          path: path,
        }
      }).then(res => {

        this.$message.success(this.imageName + res.data.msg);
        this.$emit("imageData", "");
      })
    },
    // 上传失败
    handleUploadError() {
      this.$message({
        type: "error",
        message: "上传失败",
      });
      this.loading.close();
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        strs += list[i].url.replace(this.baseUrl, "") + separator;
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : '';
    },

    //照片点击放大
    handlePictureCardPreview() {
      const image = new Image();
      image.src = this.dialogImageUrl;
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div');
        previewContainer.style.position = 'fixed';
        previewContainer.style.top = 0;
        previewContainer.style.bottom = 0;
        previewContainer.style.left = 0;
        previewContainer.style.right = 0;
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)';
        previewContainer.style.display = 'flex';
        previewContainer.style.justifyContent = 'center';
        previewContainer.style.alignItems = 'center';
        document.body.appendChild(previewContainer);
        // 在弹出层中添加图片
        const previewImage = document.createElement('img');
        previewImage.src = image.src;
        previewImage.style.maxWidth = '100%';
        previewImage.style.maxHeight = '100%';
        previewContainer.appendChild(previewImage);
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer);
        });
      };
    }
  }
};
</script>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
::v-deep.hide .el-upload--picture-card {
  display: none;
}

.el-upload--picture-card {
  width: 20px;
  height: 20px;
}

// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
  transition: all 0s;
}

::v-deep .el-list-enter, .el-list-leave-active {
  opacity: 0;
  transform: translateY(0);
}

.showImage {
  width: 150px;
  height: 150px;
}

</style>
