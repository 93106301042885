import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/regist',
        name: 'Regist',
        component: () => import('../views/Regist.vue')
    },

    {
        path: '/',
        name: 'Manage',
        redirect: "/login",
        component: () => import(/* webpackChunkName: "about" */ '../views/Manage.vue'),
        children: [{
            path: '/dict-data/index/:dictId(\\d+)',
            component: () => import('../views/dict/DictData.vue'),
            name: '字典数据'
        }, {
            path: '/recordOfChanges',
            component: () => import('../views/edit/RecordOfChanges.vue'),
            name: '信息变更'
        }, {
            path: '/Audit',
            component: () => import('../views/jxxxhy/Audit.vue'),
            name: '审核'
        }, {
            path: '/UpdateInformation',
            component: () => import('../views/search/UpdateInformation.vue'),
            name: '机械信息修改'
        },

        ]

    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/error/404.vue')
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('../views/error/500.vue')
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 提供一个重置路由的方法
/*export const resetRouter = () => {
    router.matcher = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
}*/

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
    const storeMenus = localStorage.getItem("menus");
    if (storeMenus) {

        // 获取当前的路由对象名称数组
        const currentRouteNames = router.getRoutes().map(v => v.name)
        console.log(currentRouteNames)
        if (currentRouteNames.includes('Manage')) {

            // 拼装动态路由
            const manageRoute = {
                path: '/',
                name: 'Manage',
                component: () => import('../views/Manage.vue'),
                redirect: "/home",
                children: [
                    {path: 'userInfo', name: '个人信息', component: () => import('../views/user/UserInfo.vue')},


                ]
            }
            const menus = JSON.parse(storeMenus)
            menus.forEach((item: any) => {
                if (item.path) {  // 当且仅当path不为空的时候才去设置路由
                    let itemMenu = {
                        path: item.path.replace("/", ""),
                        name: item.name,
                        component: () => import('../views/' + item.pagePath + '.vue')
                    }
                    manageRoute.children.push(itemMenu)
                } else if (item.children.length) {
                    item.children.forEach((item: any) => {
                        if (item.path) {
                            let itemMenu = {
                                path: item.path.replace("/", ""),
                                name: item.name,
                                component: () => import('../views/' + item.pagePath + '.vue')
                            }
                            console.log(itemMenu)
                            manageRoute.children.push(itemMenu)
                        }
                    })
                }
            })
            // 动态添加到现在的路由对象中去
            router.addRoute(manageRoute)
        }

    }
}

// 重置我就再set一次路由
setRoutes()
// 全局的前置守卫
router.beforeEach((to, from, next) => {
    //console.log(to.name)
    if (typeof to.name === "string") {

        localStorage.setItem("currentPathName", to.name)//设置路由当前名称
        store.commit("setPath")//触发store的数据更新
        // 未找到路由的情况
        if (!to.matched.length) {
            const storeMenus = localStorage.getItem("menus")

            if (storeMenus) {
                next("/404")
            } else {
                // 跳回登录页面
                next("/login")
            }
        }

    }//设置存储，取得路径名称
    next();//放行路由
})
export default router
